import { getImage, GatsbyImage } from 'gatsby-plugin-image';
import { blogImageWrapperStyles, blogHighlightTextStyles } from "../../../../src/utils/helpers/styles.js";
import VideoPlayer from "../../../../src/components/atoms/utility/VideoPlayer";
import * as React from 'react';
export default {
  getImage,
  GatsbyImage,
  blogImageWrapperStyles,
  blogHighlightTextStyles,
  VideoPlayer,
  React
};