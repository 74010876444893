import React, { useState, useEffect } from 'react';
import { isBrowser } from '../../../utils/helpers/environment';

export default function VideoPlayer({ videoURL, classes }) {
  const [width, setWidth] = useState(isBrowser() ? window.innerWidth : 0);
  const [isMobile, setIsMobile] = useState(isBrowser() ? !/Mobi|Android/i.test(navigator.userAgent) : false);

  useEffect(() => {
    if (isBrowser()) {
      setWidth(window.innerWidth);
      setIsMobile(!/Mobi|Android/i.test(navigator.userAgent));
    }
  }, []);

  return (
    <video controls width="35%" height="35%" loop muted autoPlay={isMobile} className={classes}>
      <source src={videoURL} />
    </video>
  );
}